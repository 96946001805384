@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


:root {
  --tw-bg-opacity: 0.5; /* Adjust the opacity as needed */
}

.pafm{
  font-family: 'YourCustomFonts' !important;
  font-size: 14px !important;
}



*{


}

body {
 background-color: #000000 !important;
 overflow-x: hidden;
}

.mainfont{
  font-family: 'YourCustomFont' !important;
}


@font-face {
  font-family: 'YourCustomFont';
  src: url('./Fonts/RAGE_1.woff') format('WOFF'),


}


@font-face {
  font-family: 'YourCustomFonts';
  src: url('./Fonts/final.ttf') format('WOFF'),


}




.centerall{
  text-align: center;
  justify-content: center;
  align-items: center;

}

/* Additional font-face rules if you have more fonts */

/* Define global font styles */
.matrix-text {
  color: rgb(255, 255, 255);
  font-family: Courier New,Courier,monospace;
  text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
}


.image_anos{
  width: 60%;
}
@media screen and (min-width: 1000px) {
                        
                                
  .image_anos{
    width: 50%;
  }

      }



.anos_text{
  font-size: 2rem !important;
  color: rgb(251, 251, 251);
  font-family: Courier New,Courier,monospace;
  text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
}


@media screen and (min-width: 1000px){
  .anos_text{
    font-size: 5rem !important;
    color: rgb(255, 255, 255);
    font-family: Courier New,Courier,monospace;
    text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
  }
  
}




.anon_p{

  color: rgb(251, 251, 251);
  font-family: Courier New,Courier,monospace;
  text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
}


@media screen and (min-width: 1000px){
  .anon_p{
 
    color: rgb(255, 255, 255);
    font-family: Courier New,Courier,monospace;
    text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
  }
  
}

.image_anos_uni{
  width: 60%;
}

@media screen and (min-width: 1000px){
  .image_anos_uni{
    width: 50%;
  }
}

.image_anos_dex{
  width: 55%;
}

@media screen and (min-width: 1000px){
  .image_anos_dex{
    width: 45%;
  }
}

.blue-text {
  font-size: 1rem !important;
  color: #ffffff;
  font-family: Courier New,Courier,monospace;
  text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
}


@media screen and (min-width: 1000px){
  .blue-text {
    color: #ffffff;
    font-size: 2.5rem !important;
    font-family: Courier New,Courier,monospace;
    text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
  }
  
}



.red-text {
  font-size:1rem !important;
  color: #ffffff;
  font-family: Courier New,Courier,monospace;
  text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
}


@media screen and (min-width: 1000px){
  .red-text {

    font-size: 2.5rem !important;
    color: #ffffff;
  font-family: Courier New,Courier,monospace;
  text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
  }
  
}



.token_text{
  font-size: 1.4rem !important;
  color: rgb(255, 255, 255);

  text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
}


@media screen and (min-width: 1000px){
  .token_text{
    font-size: 1.4rem !important;
    color: rgb(255, 255, 255);

    text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
  }
  
}




.card {
    position: relative;
    width: auto;
    height: 100%;
    background-color: #000;
    border-radius: 8px;
}






























@import"https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap";.inputCls[type=number] {
  -moz-appearance: textfield
}


.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px
}

.card {
  position: relative;
  width: auto;
  height: 100%;
  background-color: #000000c1 !important;
  border-radius: 8px
}

.card-content {
  padding: 20px
}

.card-title {
  margin: 0;
  font-size: 24px
}

.card-description {
  margin: 10px 0
}

.card-link {
  display: inline-block;
  padding: 8px 12px;
  background-color: #ff0400;
  color: #fff;
  text-decoration: none;
  border-radius: 4px
}

.boarding {
  position: absolute;
  top: -4px;
  left: -4px;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  border: 4px solid #bb00ff;
  pointer-events: none;
  border-radius: 12px;
  opacity: 0;
  animation: boardingAnimation 2s linear infinite
}

@keyframes boardingAnimation {
  0% {
      opacity: 0
  }

  50% {
      opacity: 1
  }

  to {
      opacity: 0
  }
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: repeat
}

*,:before,:after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb
}

:before,:after {
  --tw-content: ""
}

html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";
  font-feature-settings: normal
}



.tokcolo{
  color:  #fff !important;
  font-size: 3rem !important;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #070707a8 !important;
}


/* Style the audio container */
audio {
  background-color:transparent !important;
  color: white;
  width: 300px;
}

/* Style the audio controls */
audio::-webkit-media-controls {
  background-color: #a306067b;
  border-radius: 40%;
}

audio::-webkit-media-controls-play-button {
  background-color: #7c040481;
}

audio::-webkit-media-controls-volume-slider {
  background-color: #ff222263;
}

audio::-webkit-media-controls-panel {
  background-color: #860000d8;
  color: white;
}

/* Style the audio controls for Firefox */
audio::-moz-media-controls {
  background-color: #444;
  color: white;
}

/* Style the audio controls for Edge/IE */
audio::-ms-media-controls {
  background-color: #444;
  color: white;
}





.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
 width: 100%;
  pointer-events: auto;
  background-color: #ffffff !important;
  background-clip: padding-box;
  border: 2px solid rgb(0, 0, 0);
  border-radius: 0.5rem;
  outline: 0;
  top: 150px;
}







































































:root {
  --glow-color: hsla(0, 0%, 100%, 0.882);
}




.glowing-btn {
  position: relative;
  color: var(--glow-color);
  cursor: pointer;
  padding: 0.25em 1em;
  border: 0.15em solid var(--glow-color);
  border-radius: 0.45em;
 background-color: #000000;
  perspective: 2em;
  font-family: Courier New,Courier,monospace;
  font-size: 2em;
  font-weight: 700;
  letter-spacing: 1em;

  -webkit-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  -moz-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  animation: border-flicker 2s linear infinite;
}

.glowing-txt {
  float: left;
  margin-right: -0.8em;
  -webkit-text-shadow: 0 0 0.125em hsl(0, 0%, 0%),
    0 0 0.45em var(--glow-color);
  -moz-text-shadow: 0 0 0.125em hsl(0, 0%, 0%),
    0 0 0.45em var(--glow-color);
  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em var(--glow-color);
  animation: text-flicker 3s linear infinite;
}

.faulty-letter {
  opacity: 0.5;
  animation: faulty-flicker 2s linear infinite;
}

.glowing-btn::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.7;
  filter: blur(1em);
  transform: translateY(120%) rotateX(95deg) scale(3, 0.35);
  background: var(--glow-color);
  pointer-events: none;
}

.glowing-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: -1;
  background-color: var(--glow-color);
  box-shadow: 0 0 2em 0.2em var(--glow-color);
  transition: opacity 100ms linear;
}

.glowing-btn:hover {
  color: rgba(0, 0, 0, 0.8);
  text-shadow: none;
  animation: none;
}

.glowing-btn:hover .glowing-txt {
  animation: none;
}

.glowing-btn:hover .faulty-letter {
  animation: none;
  text-shadow: none;
  opacity: 1;
}

.glowing-btn:hover:before {
  filter: blur(1.5em);
  opacity: 1;
}

.glowing-btn:hover:after {
  opacity: 1;
}

@keyframes faulty-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 0.1;
  }
  4% {
    opacity: 0.5;
  }
  19% {
    opacity: 0.5;
  }
  21% {
    opacity: 0.1;
  }
  23% {
    opacity: 1;
  }
  80% {
    opacity: 0.5;
  }
  83% {
    opacity: 0.4;
  }

  87% {
    opacity: 1;
  }
}

@keyframes text-flicker {
  0% {
    opacity: 0.1;
  }

  2% {
    opacity: 1;
  }

  8% {
    opacity: 0.1;
  }

  9% {
    opacity: 1;
  }

  12% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0.3;
  }
  30% {
    opacity: 1;
  }

  70% {
    opacity: 0.7;
  }
  72% {
    opacity: 0.2;
  }

  77% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.9;
  }
}

@keyframes border-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 1;
  }
  4% {
    opacity: 0.1;
  }

  8% {
    opacity: 1;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .glowing-btn{
    font-size: 1em;
  }
}




.dextop{
  position: relative;
  top: 8px;
}





.doyou {
  font-family: "Poppins", system-ui !important;
  text-decoration: underline;
  font-size: 13px !important;
  text-underline-offset: 10px !important; /* Adjust the value as needed */
}
@media screen and (min-width: 1000px){
  .doyou {
    font-family: "Poppins", system-ui !important;
    text-decoration: underline;
    font-size: 20px !important;
    text-underline-offset: 10px !important; /* Adjust the value as needed */
  }
  
}
.typing-demo {
  font-family: "Poppins", system-ui !important;
  font-size: 11px !important;

}

@media screen and (min-width: 1000px){
  .typing-demo {
    font-family: "Poppins", system-ui !important;
    font-size: 14px !important;
  
  }
  
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: calc(100% + 2ch); /* Adjust width based on text */
  }
}

@keyframes blink-caret {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}

.glowing-checkbox {
  top: 5px;
  right: 5px;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #555;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
  /* Initial scale for animation */
  transform: scale(1);
}

.glowing-checkbox:checked {
  background-color: #000000;

  border-color: #000000;
  /* Glowing box shadow when checked */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  /* Smoothly scale up the checkbox when checked */
  transform: scale(1.2);
  transition: all 0.3s ease-in-out;
}

.checkbox-text {
  font-size: 10px !important;
  font-family: "Poppins", system-ui !important;
  color: #000000;
  margin-left: 10px;
  /* Add fade-in effect to the text */


  transition: all 0.4s ease;
}
@media screen and (min-width: 1000px){
  .checkbox-text {
    font-size: 12px !important;
    font-family: "Poppins", system-ui !important;
    color: #000000;
    margin-left: 10px;
    /* Add fade-in effect to the text */
  
  
    transition: all 0.4s ease;
  }
}



.glowing-checkbox:checked + .checkbox-text {
  /* Fade in and slide the text when the checkbox is checked */
  opacity: 1;
  transform: translateX(0);
}

/* Animation when hovering over checkbox */
.glowing-checkbox:hover {
  /* Subtle glow effect when hovering */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  /* Slight scale effect on hover */
  transform: scale(1.1);
}


.Homepage{
  font-family: 'YourCustomFonts' !important;
  background-attachment: fixed;
  border: 10px solid #000;
  height: 100vh;
  background-image: url('./PC.gif')!important;
  background-repeat: no-repeat!important;
  background-size: cover !important;

  /* Set the background color with opacity and color using a CSS variable */
  background-color: rgba(255, 0, 0, var(--tw-bg-opacity)); /* Red color with variable opacity */
}




.styled-button {
  font-size: 35px !important;
  color: black;

  background-color: #fefefe;
  border: 1px solid #2f2f3f;
  height: 44px;
  width: 110px;
  box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.8); /* Shadow on the left side */
  transition: transform 0.2s ease;
  cursor: pointer;
}

.styled-button:hover {
  transform: scale(1.05); /* Add a slight zoom effect when hovered */
}






.styled-buttonh {
  font-size: 35px !important;
  color: black;

  background-color: #fefefe;
  border: 1px solid #2f2f3f;
  height: 44px;
  width: 150px;
  box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.8); /* Shadow on the left side */
  transition: transform 0.2s ease;
  cursor: pointer;
}

.styled-buttonh:hover {
  transform: scale(1.05); /* Add a slight zoom effect when hovered */
}














.button-container {
  display: flex;
  justify-content: end;
  text-align: end;
  align-items: end;



}






































.x-button {
  background-color: #000000; /* Button background color (brownish shade) */
  border: none;
  border-radius: 12px; /* Rounded corners */
position: relative;
top: 16px;
right:71px;
border: 1px solid #888888;
  cursor: pointer;
height: 35px;
width: 38px;
  align-items: center;
  justify-content: center;
}

.x-icon {
  color: white; /* Icon color */
  font-size: 20px !important; /* Adjust the size of the icon */
}

.x-button:hover {
  background-color: #000000; /* Slightly darker color on hover */
}


.tw{
  position: relative;
  left:  0px !important;
}

.ds{
  position: relative;
  left:  25px !important;
}



.HomepageAbout {
  background-attachment: fixed;
  background-image: url('./PC.gif') !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;

  border: 10px solid #000;

  position: relative; /* Allows for absolute positioning of inner content */
  overflow: hidden; /* Ensure content stays within the container */
}

.BlurBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background-image: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(10px); /* Apply blur to only the background */
  z-index: 0; /* Keeps it behind the content */
}

.GlassOverlay {
  position: relative;
  width: 80%; /* Adjust to your desired size */
  padding: 20px;

  color: #000; /* Text color */
  z-index: 1; /* Keeps content above the blurred background */
  margin: 50px auto; /* Centers the overlay */
  text-align: center; /* Centers text inside */
}






























.about_container {
  display: flex;
  justify-content: center;
  align-items: center;

 
  margin: 0;
}

.about-button {
  padding: 20px 55px;
  border: 3px solid #000000; /* Dark purple border */
  background-color: #f3f3f3; /* White background */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); /* Soft shadow for the button */
  border-radius: 5px; /* Slight rounding of the corners */
}

.about-button .text {
  
  font-size: 26px !important;
  font-family: 'YourCustomFonts';
  color: #000; /* Black text color */
}

.about-button .diamond {
  font-family: 'Arial', sans-serif;
  font-size: 22px;
  font-weight: normal;
  display: inline-block;
  transform: scale(1.2); /* Enlarge the diamond a little */
}


























.pafk {
  font-family: 'YourCustomFonts' !important;
  font-size: 21px !important;
  text-decoration: underline; /* Add underline */
}




.paf{
  font-family: 'YourCustomFonts' !important;
  font-size: 16px !important;
}




.containera {
text-align: start;
font-family: 'YourCustomFonts' !important;
  margin: 50px auto;
  padding: 50px 20px 30px 30px;
  background-color: white;
  border: 5px solid #000000;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  line-height: 1.8;
  white-space: pre-line;
}

.container p {
  margin-bottom: 20px;
  font-family: 'YourCustomFonts' !important;
  font-size: 16px;
}




















































.HomepageAbouth {
  background-attachment: fixed;
  background-image: url('./PC.gif');
  background-repeat: no-repeat;
  background-size: cover;

  position: relative;
  
  border: 10px solid #000;
}

.BlurBackgroundh {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  filter: blur(10px); /* Blurs the background */
  z-index: 0; /* Keep it behind other content */
}

.GlassOverlayh {
  position: relative;
  width: 100%; /* Adjust size as needed */
  padding: 20px;
 
  border-radius: 1px; /* Add smooth corners */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  backdrop-filter: blur(5px); /* Glass-like blur effect */
  
  color: #000;
  z-index: 1; /* Keeps content above the blurred background */
  margin: 0px auto; /* Centers the overlay horizontally */
  text-align: center;
}

































.HomepageAboutha {
  background-attachment: fixed;
  background-image: url('./MC.gif');
  background-repeat: no-repeat;
  background-size: cover;

  position: relative;
  
  border: 10px solid #000;
}

.BlurBackgroundha {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  filter: blur(10px); /* Blurs the background */
  z-index: 0; /* Keep it behind other content */
}

.GlassOverlayha {
  position: relative;
  width: 100%; /* Adjust size as needed */
  padding: 20px;
 
  border-radius: 1px; /* Add smooth corners */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  backdrop-filter: blur(5px); /* Glass-like blur effect */
  
  color: #000;
  z-index: 1; /* Keeps content above the blurred background */
  margin: 0px auto; /* Centers the overlay horizontally */
  text-align: center;
}

















.containeram {
  text-align: start;

    padding:  40px;
    background-color: #fefefe;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    line-height: 1.8;
    white-space: pre-line;
  }
  























  .bgss{
    font-size: 30px !important;
    background-color: #f3f3f3 !important;
    text-align: center !important;
  }













  .Homepagemob{
    font-family: 'YourCustomFonts' !important;
    background-attachment: fixed;
    border: 10px solid #000;
    height: 100vh;
    width: 100%;
    background-image: url('./MC.gif')!important;
    background-repeat: no-repeat!important;
    background-size: cover !important;
  
    /* Set the background color with opacity and color using a CSS variable */
    background-color: rgba(255, 0, 0, var(--tw-bg-opacity)); /* Red color with variable opacity */
  }
  
  

  .socials_mobile{
    position: relative;
    right: 44px;
  }

  .uncheck {
    color: #1100ff;
    text-decoration: underline;
}

































.styled-button {
  font-size: 35px !important;
  color: black;

  background-color: #fefefe;
  border: 1px solid #2f2f3f;
  height: 44px;
  width: 110px;
  box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.8); /* Shadow on the left side */
  transition: transform 0.2s ease;
  cursor: pointer;
}

.styled-button:hover {
  transform: scale(1.05); /* Add a slight zoom effect when hovered */
}






.styled-buttonhy {
  font-size: 35px !important;
  color: black;

  background-color: #fefefe;
  border: 1px solid #2f2f3f;
  height: 44px;
  width: 110px;
  box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.8); /* Shadow on the left side */
  transition: transform 0.2s ease;
  cursor: pointer;
}

.styled-buttonhy:hover {
  transform: scale(1.05); /* Add a slight zoom effect when hovered */
}





































.styled-buttonm1 {
  font-size: 26px !important;
  color: black;
  text-align: start;
  background-color: #fefefe;
  border: 1px solid #2f2f3f;
  height: 40px;
  width: 120px;
  box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.8); /* Shadow on the left side */
  transition: transform 0.2s ease;
  cursor: pointer;
}

.styled-buttonm1:hover {
  transform: scale(1.05); /* Add a slight zoom effect when hovered */
}





.x-buttonm1 {
  background-color: #000000; /* Button background color (brownish shade) */
  transition: transform 0.2s ease;
  border-radius: 8px; /* Rounded corners */
  text-align: start;
color: black;
font-size: 26px !important;
border: 1px solid #c5c5c5;
height: 36px;
width: 35px;
box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.8); /* Shadow on the left side */
transition: transform 0.2s ease;
cursor: pointer;
}

.x-buttonm1:hover {
  transform: scale(1.05); /* Add a slight zoom effect when hovered */
}


































.styled-buttonm12 {
  font-size: 30px !important;
  color: black;


  background-color: #fefefe;
  border: 1px solid #2f2f3f;
  height: 40px;
  width: 120px;
  box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.8); /* Shadow on the left side */
  transition: transform 0.2s ease;
  cursor: pointer;
}

.styled-buttonm12:hover {
  transform: scale(1.05); /* Add a slight zoom effect when hovered */
}









































.styled-buttont {
  font-size: 35px !important;
  color: black;
  position: relative;

  background-color: #fefefe;
  border: 1px solid #2f2f3f;
  height: 44px;
  width: 150px;
  box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.8); /* Shadow on the left side */
  transition: transform 0.2s ease;
  cursor: pointer;
}

.styled-buttont:hover {
  transform: scale(1.05); /* Add a slight zoom effect when hovered */
}









.x-iconx {
  color: white; /* Icon color */
  font-size: 18px !important; /* Adjust the size of the icon */
position: relative;
bottom: 4px;
left: 1.5px;
}


































.styled-buttonm123 {
  font-size: 23.5px !important;
  color: black;
  background-color: #fefefe;
  border: 1px solid #2f2f3f;
  height: 36px;
  font-weight: 500;
  width: 140px;
  box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.8); /* Shadow on the left side */
  transition: transform 0.2s ease;
  cursor: pointer;
}

.styled-buttonm123:hover {
  transform: scale(1.05); /* Add a slight zoom effect when hovered */
}
























.styled-buttonm1234 {
  font-size: 27px !important;
  color: black;
position: relative;
  background-color: #000000;
  border: 2px solid #2f2f3f;
  height: 36px !important;
  font-weight: 500;
border-radius: 10px !important;
  box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.8); /* Shadow on the left side */

  cursor: pointer;
}

.styled-buttonm1234:hover {
  transform: scale(1.05); /* Add a slight zoom effect when hovered */
}





















.about_containerback {
  display: flex;
  justify-content: center;
  align-items: center;
height: 1px;
 width: 10px;
  margin: 0;
}









.about-buttonback {
  padding: 6px 15px;
  border: 3px solid #000000; /* Dark purple border */
  background-color: #f3f3f3; /* White background */


  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); /* Soft shadow for the button */
  border-radius: 5px; /* Slight rounding of the corners */
}

.about-buttonback .text {
  
  font-size: 26px !important;
  font-family: 'YourCustomFonts';
  color: #000; /* Black text color */
}






















.about_containerbackm {
  display: flex;
  justify-content: center;
  align-items: center;
height: 10px;
 width: 10px;
  margin: 0;
}









.about-buttonbackm {
  padding: 2px 10px;
  border: 3px solid #000000; /* Dark purple border */
  background-color: #f3f3f3; /* White background */


  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); /* Soft shadow for the button */
  border-radius: 5px; /* Slight rounding of the corners */
}

.about-buttonbackm .text {
  
  font-size: 26px !important;
  font-family: 'YourCustomFonts';
  color: #000; /* Black text color */
}


.enditall{
  position: relative;
  bottom: 50px;
  text-align: end;
  align-items: end;
  justify-content: end;
  display: flex;
}

.enditallm{
  position: relative;
  bottom: 10px;
  text-align: end;
  align-items: end;
  justify-content: end;
  display: flex;
}